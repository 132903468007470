<template>
  <div ref="slidePanel" class="header-container padding-1 flex">
    <div class="logo">
      <!-- https://nuxt.com/modules/icons -->
      <Icon name="LogoColor" />
    </div>

    <button
      @click="openPanel"
      :class="['hamburger-menu', { open: isPanelOpen }]"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>

    <div
      :class="['slide-panel', { open: isPanelOpen }, { closed: isPanelClosed }]"
      @click="handleClick"
    >
      <div class="slide-panel-container">
        <div class="menu-items">
          <!-- Main items -->
          <ul>
            <li>
              <NuxtLink to="/admin">
                <Icon name="dashicons:admin-home"></Icon>
                <span>Dashboard</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/event">
                <Icon name="dashicons:calendar-alt"></Icon>
                <span>Events</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/order">
                <Icon name="dashicons:cart"></Icon>
                <span>Orders</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/client">
                <Icon name="dashicons:groups"></Icon>
                <span>Clients</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/place">
                <Icon name="dashicons:admin-multisite"></Icon>
                <span>Places</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/user">
                <Icon name="dashicons:universal-access-alt"></Icon>
                <span>Users</span>
              </NuxtLink>
            </li>

            <li>
              <NuxtLink to="/admin/payouts">
                <Icon name="dashicons:redo"></Icon>
                <span>Payouts</span>
              </NuxtLink>
            </li>
          </ul>
          <br />

          <!-- Settings items -->
          <ul>
            <p>
              <Icon name="dashicons:admin-generic"></Icon><span>Settings</span>
            </p>
            <li>
              <NuxtLink to="/admin/settings/design">
                <span>Design settings</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="/admin/settings/email">
                <span>Email settings</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="/admin/settings/marketing">
                <span>Marketing tools</span>
              </NuxtLink>
            </li>
          </ul>
          <br />

          <!-- Live site -->
          <ul>
            <li>
              <NuxtLink to="/" target="_blank" rel="noopener noreferrer">
                <Icon name="dashicons:admin-site"></Icon>
                <span>Live site</span>
              </NuxtLink>
            </li>
          </ul>

          <br />
          <!-- Logout -->
          <ul>
            <li>
              <p @click="logoutAction">
                <Icon name="dashicons:admin-users"></Icon>
                <span>Logout</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth' // import the auth store we just created
import { useClickOutside } from '@/composables/clickOutside'

const { logOutUser } = useAuthStore() // use loginUser action from  auth store

const router = useRouter()

const isPanelOpen = ref(false)
const isPanelClosed = ref(false)
const slidePanel = ref(null)

const openPanel = () => {
  isPanelOpen.value = !isPanelOpen.value
  isPanelClosed.value = false

  const contentElement = document.querySelector('.content') as HTMLElement
  if (contentElement) {
    if (isPanelOpen.value) {
      contentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.2)'
      contentElement.style.opacity = '0.2'
    } else {
      contentElement.style.backgroundColor = ''
      contentElement.style.opacity = '1'
    }
  }
}

const closePanel = () => {
  const contentElement = document.querySelector('.content') as HTMLElement
  if (contentElement) {
    contentElement.style.backgroundColor = ''
    contentElement.style.opacity = '1'
  }
  isPanelOpen.value = false
  isPanelClosed.value = true
}

const handleClick = (event: any) => {
  closePanel()
}

useClickOutside(slidePanel, () => {
  closePanel()
})

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}
</script>

<style scoped>
.header-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  text-align: left;
  margin-left: 20%;
  z-index: 10;
  position: sticky;
  top: 0;
  right: 0;
}

.header-container.flex {
  justify-content: space-between;
}

.logo svg {
  height: 35px;
  width: 120px;
}

@media (max-width: 1025px) {
  .header-container {
    margin: 0 auto;
    z-index: 10;
    position: sticky;
    top: 0;
    right: 0;
  }
}

/* Mobile slide panel */

.slide-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 85%;
  height: 100%;
  background-color: #111827;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  visibility: hidden;
  z-index: 103;
}

.slide-panel.open {
  transform: translateX(0);
  visibility: visible;
}

.slide-panel.closed {
  transform: translateX(100%);
  visibility: hidden;
}

.slide-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  padding: 2em;
}

.slide-panel-trigger-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.menu-items a {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
}

.menu-items p {
  color: #ffffff;
}

.menu-items a span,
.menu-items p span {
  margin-left: 1em;
}

.menu-items li {
  margin-bottom: 1.2em;
}

.slide-panel .menu-items {
  opacity: 0;
  transition: opacity 1s ease-in-out, opacity 1s ease-in-out;
}

.slide-panel.open .menu-items {
  opacity: 1;
}

/* Hamburger menu styles */

.hamburger-menu {
  position: fixed;
  top: 30px;
  right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.hamburger-menu span {
  width: 100%;
  height: 2px;
  background: #111827;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open span {
  background: #fff;
}

.hamburger-menu.open span:nth-child(1) {
  transform: rotate(45deg) translate(8px, 5px);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -3px);
}
</style>
