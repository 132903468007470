<template>
  <div class="sidebar-container">
    <div class="inner">
      <div class="logo">
        <!-- https://icones.js.org/ -->
        <Icon name="LogoWhite" />
      </div>
    </div>
    <div class="inner">
      <div class="menu-items">
        <ul>
          <!-- 
                        https://nuxt.com/modules/icon
                        https://icones.js.org/
                     -->
          <li>
            <NuxtLink to="/admin">
              <Icon name="dashicons:admin-home"></Icon>
              <span>Dashboard</span>
            </NuxtLink>
          </li>

          <!--
                    <li class="grid-1-1">
                        <NuxtLink to="/admin/event">
                            <Icon name="dashicons:calendar-alt"></Icon>
                            <span>Events</span>
                        </NuxtLink>
                        <span class="submenu-trigger" @click="triggerSubmenuShow">
                            <Icon name="dashicons:arrow-down-alt2"></Icon>
                        </span>

                        <ul v-if="showSubmenu" class="submenu-list padding-2">
                            <li><NuxtLink to="/admin/event">
                                    <span>Event manager</span>
                                </NuxtLink></li>
                            <li><NuxtLink to="/admin/event">
                                    <span>Chart designer</span>
                                </NuxtLink></li>
                        </ul>

                    </li> 
                    
                    :class="[getRoute == 'admin-user-add' ? 'router-link-active router-link-exact-active' : '']"

                    -->

          <li>
            <NuxtLink
              to="/admin/pages"
              :class="[
                getRoute == 'admin-pages-add' || getRoute == 'admin-pages-id'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:admin-page"></Icon>
              <span>Pages</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/event"
              :class="[
                getRoute == 'admin-event-add' ||
                getRoute == 'admin-event-id' ||
                getRoute == 'admin-event-id-event-manager'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:calendar-alt"></Icon>
              <span>Events</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/order"
              :class="[
                getRoute == 'admin-order-id'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <!-- https://icones.js.org/collection/dashicons -->
              <Icon name="dashicons:cart"></Icon>
              <span>Orders</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/client"
              :class="[
                getRoute == 'admin-client-id' ||
                getRoute == 'admin-client-add' ||
                getRoute == 'admin-client-id-payouts' ||
                getRoute == 'admin-client-id-stats'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:groups"></Icon>
              <span>Clients</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/place"
              :class="[
                getRoute == 'admin-place-id' || getRoute == 'admin-place-add'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:admin-multisite"></Icon>
              <span>Places</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/sponsor"
              :class="[
                getRoute == 'admin-sponsor-id' ||
                getRoute == 'admin-sponsor-add'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:megaphone"></Icon>
              <span>Sponsors</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/user"
              :class="[
                getRoute == 'admin-user-id' || getRoute == 'admin-user-add'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:groups"></Icon>
              <span>Users</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/payouts"
              :class="[
                getRoute == 'admin-payouts'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:redo"></Icon>
              <span>Payouts</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/admin/requests"
              :class="[
                getRoute == 'admin-requests'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:editor-help"></Icon>
              <span>Requests</span>
            </NuxtLink>
          </li>

          <li v-if="authenticated">
            <a href="/login" @click="logoutAction">
              <Icon name="dashicons:unlock"></Icon>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="inner">
      <div class="menu-items sticky-bottom">
        <ul>
          <li>
            <NuxtLink
              to="/admin/deploy"
              :class="[
                getRoute == 'admin-deploy'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:external"></Icon>
              <span>Deploy</span>
            </NuxtLink>
          </li>

          <li @click="triggerSubmenuShow">
            <NuxtLink
              to="#"
              :class="[
                getRoute == 'admin-settings-design' ||
                getRoute == 'admin-settings-marketing' ||
                getRoute == 'admin-settings-email'
                  ? 'router-link-active router-link-exact-active'
                  : '',
              ]"
            >
              <Icon name="dashicons:admin-generic"></Icon>
              <span>Settings</span>
            </NuxtLink>

            <ul v-if="showSubmenu" class="submenu-list">
              <!---->
              <li>
                <NuxtLink to="/admin/settings/design">
                  <span>Design settings</span>
                </NuxtLink>
              </li>
              <li>
                <NuxtLink to="/admin/settings/email">
                  <span>Email settings</span>
                </NuxtLink>
              </li>
              <li>
                <NuxtLink to="/admin/settings/marketing">
                  <span>Marketing tools</span>
                </NuxtLink>
              </li>
            </ul>
          </li>

          <li>
            <NuxtLink to="/" target="_blank" rel="noopener noreferrer">
              <Icon name="dashicons:admin-site"></Icon>
              <span>Live site</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia' // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth' // import the auth store we just created

const { logOutUser } = useAuthStore() // use loginUser action from  auth store

const { authenticated } = storeToRefs(useAuthStore()) // make authenticated state reactive with storeToRefs

const router = useRouter()
const { currentRoute } = useRouter()

const showSubmenu = ref(false)

const getRoute = ref(currentRoute.value.name)

const triggerSubmenuShow = () => {
  showSubmenu.value = !showSubmenu.value
}

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}

watch(
  currentRoute,
  (value) => {
    getRoute.value = value.name
    console.log('getRoute', getRoute.value)
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
.sidebar-container {
  background-color: #111827;
  text-align: left;
  position: fixed;
  left: 0;
  width: 20%;
  height: 100vh;
  z-index: 11;
  overflow-y: scroll;
}

.sidebar-container .inner {
  padding: 2em;
}

.logo {
  padding: 0 0.8em;
}

.logo .icon {
  line-height: 1;
  font-size: 4rem;
  display: block;
  color: #ffffff;
  margin-bottom: 24px;
}

.logo .icon svg {
  vertical-align: middle;
}

.menu-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  margin-bottom: 0.8em;
}

.menu-items li:hover {
  background-color: #1f2937;
  border-radius: 10px;
}

.menu-items li:hover a,
.menu-items li:hover .icon {
  color: #ffffff;
}

.menu-items li a.router-link-active,
.menu-items li a.router-link-active .icon {
  color: #ffffff;
}

.menu-items li a.router-link-active,
.menu-items li a.router-link-active .icon {
  color: #ffffff;
}

.menu-items li:has(> a.router-link-active) {
  background-color: #1f2937;
  border-radius: 10px;
}

.menu-items li .icon {
  margin-right: 0.5em;
  font-size: 1.6rem;
  color: #9ba3af;
  line-height: 1;
}

.menu-items li .icon svg {
  color: #9ba3af;
  stroke: none;
  fill: #ffffff;
}

.menu-items li .submenu-trigger:hover {
  color: #ffffff;
  cursor: pointer;
}

.menu-items li .submenu-list {
  padding: 1em 2em;
}

.menu-items li .submenu-list a {
  display: block;
  transition: all 0.3s;
}

.menu-items li .submenu-list a:hover {
  color: #9ba3af;
}

.menu-items a {
  color: #9ba3af;
  font-family: var(--font-regular);
  font-size: 1rem;
  display: block;
  padding: 0.4em;
  line-height: 1;
  display: grid;
  grid-template-columns: 1fr 12fr;
  align-items: center;
  justify-items: left;
  transition: none;
}

.menu-items .grid-1-1 {
  justify-items: center;
  grid-template-columns: 8fr 1fr;
}
</style>
